import axios from 'axios'
// const phpAddress = 'http://127.0.0.1:8000'
const phpAddress = process.env.VUE_APP_PHP_API

const client = axios.create({
  baseURL: 'http://127.168.1.1:8082/',
  json: true
})

const clientPhp = axios.create({
  baseURL: phpAddress,
  json: true
})

export default {
  async executePhp(method, resource, data, headers) {
    return clientPhp({
      method,
      url: resource,
      data,
      headers
    }).then(req => {
      return req.data
    })
  },
  // async executePhp(method, resource, headers) {
  //   return clientPhp({
  //     method,
  //     url: resource,
  //     headers
  //   }).then(req => {
  //     return req.data
  //   })
  // },
  getDownloadData (informasi_id, param, headers) {
    return this.executePhp('post', `/api/kjn/jabatan/download/${informasi_id}`, param, headers)
  },
  getDownloadDataTka (informasi_id, param, headers) {
    return this.executePhp('post', `/api/kjn/tka/jabatan/download/${informasi_id}`, param, headers)
  },
  getDownloadDataDisabilitas (informasi_id, param, headers) {
    return this.executePhp('post', `/api/kjn/disabilitas/jabatan/download/${informasi_id}`, param, headers)
  },
  // getDownloadData (informasi_id, headers) {
  //   return this.executePhp('get', `/api/kjn/jabatan/download/${informasi_id}`, headers)
  // },
  // getJabatanDetail (informasi_id) {
  //   return this.executePhp('get', `/api/kjn/jabatan/detail/${informasi_id}`)
  // }
}